<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else>
            
                <v-row dense no-gutters class="pa-2"> 
                </v-row>
                <div id="PrintReport">
                  <table class="print_table3 md-font" style="width: 100%;">
                    <tr>
                          <td class="text-h5 text-success"><v-icon left color="secondary">mdi-database</v-icon> Current Load:</td>
                          <td class="text-h5 text-success">{{$money(account.balance)}}</td>
                      </tr>
                      <!-- <tr>
                          <td class="text-h6"><v-icon left color="secondary">mdi-percent</v-icon> Current Commission:</td>
                          <td class="text-h6">{{$money(account.comm_amount)}}<br />
                          <v-btn small @click="confirm= true">Convert to load</v-btn></td>
                      </tr> -->
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details</v-icon> Account  #:</td>
                          <td>{{account.id}}</td>
                      </tr>
                        <tr>
                          <td><v-icon left color="secondary" small>mdi-shield-account</v-icon>Username:</td>
                          <td>{{account.username}}</td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details-outline</v-icon>Fullname</td>
                          <td>{{account.fullname}}</td>
                      </tr>
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details</v-icon> Account Type:</td>
                          <td>{{account.role}}</td>
                      </tr>
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-cellphone-basic</v-icon>Contact No.</td>
                          <td>{{account.contact_no}}</td>
                      </tr>
                    
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-wallet</v-icon>Commission</td>
                          <td>
                            {{account.commission}}%
                          </td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-key-change</v-icon>Password</td>
                          <td>*** <v-icon right color="warning" small @click="passform=true">mdi-pencil</v-icon></td>
                      </tr>
                      
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
    <va-password-form :show="passform" @DialogEvent="pasEvent"/>
    <va-convert-form :show="confirm" :account="account" @DialogEvent="conEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      passform: false,
      confirm: false,
        search:'',
        from_date:'',
        to_date: '',
        date_range:"",
        account:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
 this.setDrawer(false)
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.userDetails()
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
     
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      pasEvent(){
        this.passform = false
        this.conform = false
      },
      conEvent(e) {
        this.confirm = false
        if(e != "close") {
            this.addLoad(e)
        }
      },

     userDetails(){
       console.log( 'call', this.user)
          this.$http.post("user/details",{id: this.user.id}).then(response => {
            console.log(response.data.account)
              if(response.data.status) {
                 this.account = response.data.account
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
    addLoad(load){
         console.log(load)
          this.$http.post("wallet/load",load).then(response => {
            this.userDetails()
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                 
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
    },
  }
</script>